import { ChangeBusinessType } from 'commands/business/ChangeBusinessType';

import { CommandBatch } from 'commands/Commands';
import { EditContext } from 'context/EditContext';
import React, { useState } from 'react';
import { useContext } from 'react';
import BusinessEditControls from '../BusinessEditControls';

type Props = {
  businessType: string;
  commands: CommandBatch;
  showControls?: boolean;
};
const Residential = 'Residential';
const Commercial = 'Commercial';

const BusinessTypeSelector = ({ businessType, commands, showControls = true }: Props) => {
  const [value, setValue] = useState<string>(businessType?.trim() ? businessType : Residential);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { setDirty } = useContext(EditContext);
  const handleChangeBusinessType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const select = e.target;
    setDirty(true);
    commands.add(new ChangeBusinessType(select.value));
    setValue(select.value);
  };
  return (
    <div>
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>Business Type:</h3>
        <div className='flex'>
            {!isEditing ? (
                <span className='px-3 py-1 bg-green-100 text-green-700 rounded-full'>{value}</span>
                ) : (
                <select className='mx-3 bg-gray-100 rounded-md p-1' value={value} onChange={handleChangeBusinessType}>
                    <option value={Residential}>{Residential}</option>
                    <option value={Commercial}>{Commercial}</option>
                </select>
            )}
          {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
        </div>
      </div>
    </div>
  );
};

export default BusinessTypeSelector;
