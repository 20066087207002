import React, { useContext, useState } from 'react';

import { ChangeDisclaimer } from 'commands/business/ChangeByobDisclaimer';
import { ChangePrivacyPolicy } from 'commands/business/ChangeByobPrivacyPolicy';
import { ChangeTermsAndConditions } from 'commands/business/ChangeByobTermsAndConditions';

import { ChangeHasBlog } from 'commands/business/ChangeHasBlog';
import { ChangeHasCustomPages } from 'commands/business/ChangeHasCustomPages';

import { ChangeLeadBroker } from 'commands/business/ChangeLeadBroker';
import { ChangeLegal } from 'commands/business/ChangeLegal';
import { ChangeCtrLink } from 'commands/business/ChangeCtrLink';

import { ChangeEnableScripts } from 'commands/business/ChangeEnableScripts';
import { ChangeHeadScripts } from 'commands/business/ChangeHeadScripts';
import { ChangeBodyScripts } from 'commands/business/ChangeBodyScripts';

import { CommandBatch } from 'commands/Commands';
import { UserContext } from 'context/UserContext';
import { BusinessDetails } from 'models/business';
import BusinessLeadBrokerSelect from 'components/MyBusiness/Inputs/BusinessLeadBrokerSelect';
import BusinessBoard from './BusinessBoard';

import BusinessBooleanSelect from './Inputs/BusinessBooleanSelect';
import BusinessEditableText from './Inputs/BusinessEditableText';
import BusinessFileUploadPicker from './Inputs/BusinessFileUploadPicker';
import BusinessTypeSelector from './Inputs/BusinessTypeSelector';

import BusinessSpecialisations from './Inputs/BusinessSpecialisations';
import BusinessTextAreaModal from './Inputs/BusinessTextAreaModal';
import BusinessWebsiteStatus from './Inputs/BusinessWebsiteStatus';
import BusinessStates from './Inputs/BusinessStates';
import { ChangeBusinessStatement } from 'commands/business/ChangeBusinessStatement';

type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
};
const WebsiteConfigFields = ({ business, commands }: Props) => {
  const { user } = useContext(UserContext);
  const [showCustomScripts, setShowCustomScripts] = useState(business.details.enableScripts || false);

  const corporateUser = user.corporateUser;

  return (
    <BusinessBoard heading='Website Configuration'>
      <BusinessWebsiteStatus visible={business.details?.visible} commands={commands} showControls={corporateUser} />
      <hr />
      <BusinessBooleanSelect
        label='Has Blogs'
        visible={business.hasBlog}
        commands={commands}
        commandFactory={value => new ChangeHasBlog(value)}
        showControls={corporateUser && !business.details?.byob}
      />
      <hr />
      <BusinessBooleanSelect
        label='Has Custom Pages'
        visible={business.hasCustomPages}
        commands={commands}
        commandFactory={value => new ChangeHasCustomPages(value)}
        showControls={corporateUser && !business.details?.byob}
      />
      <hr />
      <BusinessTypeSelector businessType={business.details?.businessType} commands={commands} showControls={corporateUser} />
      <hr />
      <div className='flex justify-between mx-5 mt-2 mb-3'>
        <h3 className='text-lg text-gray-700'>BYOB Branded: </h3>
        <span
          className={`font-normal px-3 py-1 ${business.details?.byob ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-800'} rounded-full`}
        >
          {business.details?.byob.toString()}
        </span>
      </div>

      {business.details?.byob ? (
        <>
          <hr />
          <BusinessTextAreaModal
            label='BYOB Legal'
            initialVal={business.details.legal}
            commands={commands}
            commandFactory={value => new ChangeLegal(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessTextAreaModal
            label='BYOB Disclaimer'
            initialVal={business.details.disclaimer}
            commands={commands}
            commandFactory={value => new ChangeDisclaimer(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessFileUploadPicker
            label='BYOB Terms & Conditions'
            initialVal={business.details.termsAndConditions}
            commands={commands}
            commandFactory={value => new ChangeTermsAndConditions(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessFileUploadPicker
            label='BYOB Privacy Policy'
            initialVal={business.details.privacyPolicy}
            commands={commands}
            commandFactory={value => new ChangePrivacyPolicy(value)}
            showControls={corporateUser}
          />
        </>
      ) : user.corporateUser ? (
        <>
          <hr />
          <BusinessTextAreaModal
            label='Legal'
            initialVal={business.details.legal}
            commands={commands}
            commandFactory={value => new ChangeLegal(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessTextAreaModal
            label='Disclaimer'
            initialVal={business.details.disclaimer}
            commands={commands}
            commandFactory={value => new ChangeDisclaimer(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessFileUploadPicker
            label='Terms & Conditions'
            initialVal={business.details.termsAndConditions}
            commands={commands}
            commandFactory={value => new ChangeTermsAndConditions(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessFileUploadPicker
            label='Privacy Policy'
            initialVal={business.details.privacyPolicy}
            commands={commands}
            commandFactory={value => new ChangePrivacyPolicy(value)}
            showControls={corporateUser}
          />
        </>
      ) : null}
      <hr />
      <BusinessSpecialisations commands={commands} selectedSpecialisations={business.details?.specialisations} />
      <hr />
      <BusinessStates commands={commands} selectedStates={business.details?.states.map(selectedState => selectedState.toUpperCase())} />
      <hr />
      <BusinessLeadBrokerSelect initialLeadBroker={business.leadBroker} commands={commands} commandFactory={value => new ChangeLeadBroker(value)} />
      <hr />
      <BusinessEditableText
        label='CTA Url'
        initialVal={business.details.ctrLink}
        commands={commands}
        commandFactory={value => new ChangeCtrLink(value)}
        showControls={corporateUser}
      />
      <hr />
      <BusinessTextAreaModal
        label='Business Statement'
        initialVal={business.details.statement}
        commands={commands}
        commandFactory={value => new ChangeBusinessStatement(value)}
        showControls={corporateUser}
        maxCharacters={23}
      />
      <hr />
      <BusinessBooleanSelect
        label='Enable Custom Website Scripts'
        visible={business.details.enableScripts}
        commands={commands}
        commandFactory={value => new ChangeEnableScripts(value)}
        showControls={corporateUser}
        setBooleanState={setShowCustomScripts}
      />
      {showCustomScripts ? (
        <>
          <hr />
          <BusinessTextAreaModal
            label='Website Head Scripts'
            initialVal={business.details.headScripts}
            commands={commands}
            commandFactory={value => new ChangeHeadScripts(value)}
            showControls={corporateUser}
          />
          <hr />
          <BusinessTextAreaModal
            label='Website Body Scripts'
            initialVal={business.details.bodyScripts}
            commands={commands}
            commandFactory={value => new ChangeBodyScripts(value)}
            showControls={corporateUser}
          />
        </>
      ) : null}
    </BusinessBoard>
  );
};

export default WebsiteConfigFields;
